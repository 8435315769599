import React from 'react';
import { Avatar, IconButton } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

import { AppBarStyled, ToolbarStyled } from './AppBar.style';
import { PageTitle } from '../../globalStyle/FontStyles';

import { ReactComponent as TrophyIcon } from '../../assets/icons/emoji_events_black_24dp.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add_black_24dp.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/arrow_back_black_24dp.svg';
import { useProfilePicture } from '../UserProvider/UserProvider';

interface AppBarProps {
  isLandingPage?: boolean;
  showBackButton?: boolean;
  title: string;
}

export const AppBar: React.FC<AppBarProps> = ({ isLandingPage, showBackButton, title }: AppBarProps) => {
  const profilePictureQuery = useProfilePicture();
  const history = useHistory();

  function renderProfileButton() {
    if (showBackButton) {
      return null;
    }

    return (
      <Link to="/profile">
        <Avatar src={profilePictureQuery?.data} />
      </Link>
    );
  }

  function renderLeftIcon() {
    if (isLandingPage) {
      return (
        <IconButton edge="start" color="inherit" aria-label="menu">
          <TrophyIcon />
        </IconButton>
      );
    }

    if (showBackButton) {
      return (
        <IconButton onClick={() => history.goBack()} edge="start" color="inherit" aria-label="menu">
          <BackIcon />
        </IconButton>
      );
    }

    return null;
  }

  function renderAddIcon() {
    if (!isLandingPage) {
      return null;
    }

    return (
      <IconButton component={Link} to="/newActivity" color="inherit" aria-label="menu">
        <AddIcon />
      </IconButton>
    );
  }

  return (
    <AppBarStyled position="sticky">
      <ToolbarStyled>
        {renderLeftIcon()}
        <PageTitle>{title}</PageTitle>
        {renderAddIcon()}
        {renderProfileButton()}
      </ToolbarStyled>
    </AppBarStyled>
  );
};
