import React from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { KeyboardDateTimePickerStyled } from './DateTimePicker.style';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface Props {
  value: any;
  onChange: (date: MaterialUiPickersDate | null, value?: string | null) => void;
}

export const DateTimePicker: React.FC<Props> = ({ value, onChange }) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDateTimePickerStyled
        disableToolbar
        variant="inline"
        autoOk={true}
        format="DD/MM/YYYY HH:mm"
        margin="normal"
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        ampm={false}
        disableFuture={true}
        minutesStep={5}
      />
    </MuiPickersUtilsProvider>
  );
};
