import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { StylesProvider } from '@material-ui/core';
import { MsalProvider } from '@azure/msal-react';
import { EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';

import { getMsalConfig } from './authConfig';
import InformationPage from './pages/InformationPage/InformationPage';
import { ActivityHistoryPage } from './pages/ActivityHistoryPage/ActivityHistoryPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import { NewActivityPage } from './pages/NewActivityPage/NewActivityPage';
import { ActivityResultPage } from './pages/ActivityResultPage/ActivityResultPage';
import { UserProvider } from './components/UserProvider/UserProvider';
import { TeamFeedPage } from './pages/TeamFeed/TeamFeedPage';
import { ActivityDetailPage } from './pages/ActivityDetailPage/ActivityDetailPage';

export const msalInstance = new PublicClientApplication(getMsalConfig());

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload as any).account) {
    const account = (event.payload as any).account;
    msalInstance.setActiveAccount(account);
  }
});

export const queryClient = new QueryClient();

export const App: React.FC = () => {
  return (
    <StylesProvider injectFirst>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <MsalProvider instance={msalInstance}>
            <UserProvider>
              <Switch>
                <Route exact path="/">
                  <TeamFeedPage />
                </Route>
                <Route exact path="/newActivity">
                  <NewActivityPage />
                </Route>
                <Route path="/activity/:activityId">
                  <ActivityDetailPage />
                </Route>
                <Route exact path="/activityhistory">
                  <ActivityHistoryPage />
                </Route>
                <Route exact path="/activityResult">
                  <ActivityResultPage />
                </Route>
                <Route exact path="/info">
                  <InformationPage />
                </Route>
                <Route exact path="/profile">
                  <ProfilePage />
                </Route>
              </Switch>
            </UserProvider>
          </MsalProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </StylesProvider>
  );
};
