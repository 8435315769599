import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { useMsal } from '@azure/msal-react';

import { ReactComponent as LogoutIcon } from '../../assets/icons/logout_black_24dp.svg';
import ActionButton from '../Button/ActionButton';

export const LogoutButton: React.FC = () => {
  const { instance } = useMsal();

  const handleLogout = async () => {
    await instance.logout({
      onRedirectNavigate: (url) => true
    });
  };

  return (
    <ActionButton onClick={handleLogout} icon={<SvgIcon component={LogoutIcon} />}>
      Logout
    </ActionButton>
  );
};
