import React from 'react';
import './InformationPage.style';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import {
  ImageContainer,
  ImageOverlay,
  Score,
  HDivider,
  ContentContainer,
  ActionContainer
} from './InformationPage.style';
import { Copy, CopyHighlighted, FactSubline, PageTitle } from '../../globalStyle/FontStyles';
import ActionButton from '../../components/Button/ActionButton';

import headerImageInfoPage from '../../assets/images/headerImageInfoPage.png';
import { Page } from '../../models/Page';
import { RefetchConfig, useAllFitnessPoints } from '../../components/UserProvider/UserProvider';
import { DisplayQuery } from '../../components/DisplayQuery/DisplayQuery';
import { TopThreeRanking } from '../../components/TopThreeRanking/TopThreeRanking';

export default function InformationPage() {
  const allFitnessPointsQuery = useAllFitnessPoints();

  const refetchConfig: Partial<RefetchConfig> = {
    isAllFitnessPoints: true,
    isRanking: true
  };

  function renderImageContent() {
    return (
      <DisplayQuery
        query={allFitnessPointsQuery}
        loadingElement={<PageTitle> Loading data...</PageTitle>}
        errorElement={<FactSubline> Loading error :( </FactSubline>}
        noDataElement={<FactSubline> No data available :( </FactSubline>}
      >
        <div>
          <PageTitle>{(allFitnessPointsQuery?.data?.count || 0).toFixed(2)} €</PageTitle>
          <FactSubline>total</FactSubline>
        </div>
      </DisplayQuery>
    );
  }

  function renderContent() {
    return (
      <div>
        <ImageContainer>
          <img src={headerImageInfoPage} alt={'Header logo'} />
          <ImageOverlay>
            <Score>{renderImageContent()}</Score>
          </ImageOverlay>
        </ImageContainer>
        <ContentContainer>
          <CopyHighlighted>You're awesome! With your help, great regional projects can be supported.</CopyHighlighted>
          <Copy>
            Last time we donated the collected money to the children's cancer ward of the UKSH. Maybe you have another
            great idea which organisation / charitable organisation / etc. could use the money to do great things or
            make peoples' lives a bit easier?
          </Copy>
          <Copy>
            Just send a mail by clicking the button below and tell us who would really appreciate our donation.
          </Copy>

          <ActionContainer>
            <ActionButton href={'mailto:jill.weiss-luethe@uxma.com'}>Suggest a project</ActionButton>
          </ActionContainer>
        </ContentContainer>
        <HDivider />
        <TopThreeRanking />
      </div>
    );
  }

  return (
    <PageTemplate title={'Further Information'} currentPage={Page.INFORMATION} refetchConfig={refetchConfig}>
      {renderContent()}
    </PageTemplate>
  );
}
