import styled from '@emotion/styled';
import { Container, Divider } from '@material-ui/core';

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;

  img {
    width: 100%;
  }
`;

export const ImageOverlay = styled.div`
  width: 100%;
  position: absolute;
  margin-top: -16px;
  top: 50%;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const Score = styled.div`
  text-align: center;
`;

export const HDivider = styled(Divider)`
  margin-top: -3px;
  width: 100%;
`;

export const ContentContainer = styled(Container)`
  padding-top: 20px;
  font-size: 14px;
  line-height: 20px;
`;

export const ActionContainer = styled.div`
  padding-top: 4px;
  padding-bottom: 24px;
  text-align: center;
`;
