import styled from '@emotion/styled';
import { Slider } from '@material-ui/core';
import { Colors } from '../../globalStyle/Colors';
import MuiInput from '@material-ui/core/Input';

export const SliderWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const SliderLabel = styled.span`
  width: 95px;
  margin-bottom: 60px;
`;

export const SliderStyled = styled(Slider)`
  width: 70%;
  margin: 0 20px;
  color: ${Colors.SignalRed};
`;

export const SliderTextWrapper = styled.div`
  width: 95px;
  text-align: end;
`;

export const SliderInput = styled(MuiInput)`
  width: 45px;
  margin-left: -20px;
`;