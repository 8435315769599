import React from 'react';
import { useIsLoading } from '../UserProvider/UserProvider';
import { ProgressBar } from './LoadingProgressBar.style';

export const LoadingProgressBar: React.FC = () => {
  const isLoading = useIsLoading();

  if (!isLoading) {
    return null;
  }

  return <ProgressBar />;
};
