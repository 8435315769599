import styled from '@emotion/styled';

import { Colors } from './Colors';

interface TeamActivityProps {
  activityType: string;
}

export const PageTitle = styled.h3`
  font-family: 'Montserrat-Bold';
  font-size: 18px;
  line-height: 24px;
  color: ${Colors.Black};
  flex-grow: 1;
  margin: 0;
`;

export const CopyHighlighted = styled.div`
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.Black};
  margin: 0 0 5px 0;
`;

export const Copy = styled.div`
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.DoveGrey};
  margin: 0 0 10px 0;
`;

export const TeamFeedName = styled.p`
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.Black};
`;

export const LikeCountText = styled.div`
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: ${Colors.Black};
`;

function getActivityColor(_activityType: string) {
  // TODO: return color depending on activity type
  return Colors.DarkPink;
}

export const TeamFeedActivity = styled.p<TeamActivityProps>`
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => getActivityColor(props.activityType)};
`;

export const TeamFeedCopy = Copy;

export const FactHeadline = styled.div`
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: ${Colors.Black};
  font-family: 'Montserrat-Bold';
`;

export const FactSubline = styled.div`
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.Black};
`;

export const ActivityCategory = styled.div`
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: ${Colors.Black};
`;

export const ActivityScore = styled.h4`
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: ${Colors.LightGrey};
`;

export const ActivityData = styled.p`
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.LightGrey};
`;

export const LinkImprint = styled.p`
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.LightGrey};
  text-transform: uppercase;
`;
