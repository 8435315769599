import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';

export function useAutoLogin() {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      void instance.loginRedirect();
    }
  }, [isAuthenticated, inProgress, instance]);
}
