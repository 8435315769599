import styled from '@emotion/styled';
import { Grid, Typography } from '@material-ui/core';
import { Colors } from '../../globalStyle/Colors';
import { Link } from 'react-router-dom';

export const ActivityCellTextContainer = styled(Grid)`
  text-align: start;
`;

export const ActivityCellItem = styled(Grid)`
  padding: 5px 0px 5px 0px;
`;

export const AdditionalPointsText = styled(Typography)`
  margin-left: 20px;
  color: ${Colors.Grey};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-weight: bold;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
