import axios from 'axios';
import { loginRequest } from '../authConfig';
import { msalInstance } from '../App';

export async function acquireToken() {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  });

  return response.accessToken;
}

export async function getPhoto() {
  const token = await acquireToken();

  const response = await axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
    responseType: 'arraybuffer',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'image/jpeg'
    }
  });

  return createImageSrc(response.data);
}

function createImageSrc(data: any) {
  const blob = new Blob([data], {
    type: 'image/jpeg'
  });

  return URL.createObjectURL(blob);
}
