import { ReactNode } from 'react';
import { ActivityCategory } from './ActivityCategory';
import { ReactComponent as JoggingIcon } from '../assets/icons/directions_run_black_24dp.svg';
import { ReactComponent as NordicWalkingIcon } from '../assets/icons/nordic_walking_black_24dp.svg';
import { ReactComponent as CyclingIcon } from '../assets/icons/directions_bike_black_24dp.svg';
import { ReactComponent as SkatingIcon } from '../assets/icons/skateboarding_black_24dp.svg';
import { ReactComponent as HikingIcon } from '../assets/icons/hiking_black_24dp.svg';
import { ReactComponent as YogaIcon } from '../assets/icons/self_improvement_black_24dp.svg';
import { ReactComponent as WeightsIcon } from '../assets/icons/fitness_center_black_24dp.svg';
import { ReactComponent as HulaHoopIcon } from '../assets/icons/hula_hooping_black_24dp.svg';
import { ReactComponent as RopeSkippingIcon } from '../assets/icons/jumping_rope_black_24dp.svg';
import { ReactComponent as DancingIcon } from '../assets/icons/music_note_black_24dp.svg';
import { ReactComponent as WalkingIcon } from '../assets/icons/directions_walk_black_24dp.svg';
import { ReactComponent as PetsIcon } from '../assets/icons/pets_black_24dp.svg';
import { ReactComponent as GardeningIcon } from '../assets/icons/grass_black_24dp.svg';
import { ReactComponent as OtherIcon } from '../assets/icons/other_black_24dp.svg';
import { ReactComponent as SwimmingIcon } from '../assets/icons/pool_black_24dp.svg';
import { ReactComponent as SurfingIcon } from '../assets/icons/surfing_black_24dp.svg';
import { ReactComponent as KitingIcon } from '../assets/icons/kitesurfing_black_24dp.svg';
import { ReactComponent as FootballIcon } from '../assets/icons/sports_soccer_black_24dp.svg';
import { ReactComponent as BasketballIcon } from '../assets/icons/sports_basketball_black_24dp.svg';
import { ReactComponent as VolleyballIcon } from '../assets/icons/sports_volleyball_black_24dp.svg';
import { ReactComponent as TennisIcon } from '../assets/icons/sports_tennis_black_24dp.svg';
import { ReactComponent as BadmintonIcon } from '../assets/icons/badminton_black_24dp.svg';
import { ReactComponent as HandballIcon } from '../assets/icons/sports_handball_black_24dp.svg';
import { ReactComponent as BoulderIcon } from '../assets/icons/bouldern_black_24dp.svg';
import { ReactComponent as PoleDanceIcon } from '../assets/icons/pole_dance_black_24dp.svg';
import { ReactComponent as GolfIcon } from '../assets/icons/golf_course_black_24dp.svg';
import { ReactComponent as SUPIcon } from '../assets/icons/sup_black_24dp.svg';
import { ReactComponent as MartialArtsIcon } from '../assets/icons/martial_arts_black.svg';
import { ReactComponent as HorsebackRidingIcon } from '../assets/icons/horseback_riding_black.svg';
import { ReactComponent as InlineSkatingIcon } from '../assets/icons/inline_skating_black.svg';
import { ReactComponent as GymnasticsIcon } from '../assets/icons/gymnastics_black.svg';
import { ReactComponent as SailingIcon } from '../assets/icons/sailing_black_24dp.svg';
import { ReactComponent as BowlingIcon } from '../assets/icons/bowling_black_24dp.svg';

export interface ActivityType {
  type: InternalActivityType;
  color: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
}

export enum EnduranceActivities {
  Jogging = 'Jogging',
  NordicWalking = 'Nordic Walking',
  Cycling = 'Cycling',
  Skateboarding = 'Skateboarding',
  InlineSkating = 'Inline Skating',
  Hiking = 'Hiking',
  Bouldern = 'Bouldern',
  Climbing = 'Climbing',
  PoleDance = 'Pole Dance'
}

export enum FitnessActivities {
  Yoga = 'Yoga',
  Workout = 'Workout',
  HulaHoop = 'Hula Hoop',
  RopeSkipping = 'Rope Skipping',
  Dancing = 'Dancing',
  HorsebackRiding = 'Horseback Riding',
  MartialArts = 'Martial Arts',
  Gymnastics = 'Gymnastics',
  Frisbee = 'Frisbee'
}

export enum FreetimeActivites {
  Walking = 'Walking',
  Pets = 'Walking the dog',
  Gardening = 'Gardening',
  Other = 'Other activity'
}

export enum WaterActivities {
  Swimming = 'Swimming',
  Surfing = 'Surfing',
  Kitesurfing = 'Kitesurfing',
  SUP = 'Stand Up Paddling',
  Sailing = 'Sailing'
}

export enum BallActivities {
  Football = 'Football',
  Basketball = 'Basketball',
  Volleyball = 'Volleyball',
  Tennis = 'Tennis',
  Badminton = 'Badminton',
  Handball = 'Handball',
  Golf = 'Golf',
  Bowling = 'Bowling'
}

enum ActivitesError {
  ActivityNotAvailable = 'N/A'
}

const InternalActivityType = {
  ...EnduranceActivities,
  ...FitnessActivities,
  ...FreetimeActivites,
  ...WaterActivities,
  ...BallActivities,
  ...ActivitesError
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
type InternalActivityType =
  | EnduranceActivities
  | FitnessActivities
  | FreetimeActivites
  | WaterActivities
  | BallActivities
  | ActivitesError;

export const allActivityTypes = [
  ...Object.values(EnduranceActivities),
  ...Object.values(FitnessActivities),
  ...Object.values(FreetimeActivites),
  ...Object.values(WaterActivities),
  ...Object.values(BallActivities)
];

export const getActivityType = (activityType: string, category: string): ActivityType => {
  switch (activityType as InternalActivityType) {
    case InternalActivityType.Jogging:
      return getActivityTypeOverflow(InternalActivityType.Jogging, category, JoggingIcon);
    case InternalActivityType.NordicWalking:
      return getActivityTypeOverflow(InternalActivityType.NordicWalking, category, NordicWalkingIcon);
    case InternalActivityType.Cycling:
      return getActivityTypeOverflow(InternalActivityType.Cycling, category, CyclingIcon);
    case InternalActivityType.Skateboarding:
      return getActivityTypeOverflow(InternalActivityType.Skateboarding, category, SkatingIcon);
    case InternalActivityType.Hiking:
      return getActivityTypeOverflow(InternalActivityType.Hiking, category, HikingIcon);
    case InternalActivityType.Yoga:
      return getActivityTypeOverflow(InternalActivityType.Yoga, category, YogaIcon);
    case InternalActivityType.Workout:
      return getActivityTypeOverflow(InternalActivityType.Workout, category, WeightsIcon);
    case InternalActivityType.HulaHoop:
      return getActivityTypeOverflow(InternalActivityType.HulaHoop, category, HulaHoopIcon);
    case InternalActivityType.RopeSkipping:
      return getActivityTypeOverflow(InternalActivityType.RopeSkipping, category, RopeSkippingIcon);
    case InternalActivityType.Dancing:
      return getActivityTypeOverflow(InternalActivityType.Dancing, category, DancingIcon);
    case InternalActivityType.Walking:
      return getActivityTypeOverflow(InternalActivityType.Walking, category, WalkingIcon);
    case InternalActivityType.Pets:
      return getActivityTypeOverflow(InternalActivityType.Pets, category, PetsIcon);
    case InternalActivityType.Other:
      return getActivityTypeOverflow(InternalActivityType.Other, category, OtherIcon);
    case InternalActivityType.Gardening:
      return getActivityTypeOverflow(InternalActivityType.Gardening, category, GardeningIcon);
    case InternalActivityType.Swimming:
      return getActivityTypeOverflow(InternalActivityType.Swimming, category, SwimmingIcon);
    case InternalActivityType.Surfing:
      return getActivityTypeOverflow(InternalActivityType.Surfing, category, SurfingIcon);
    case InternalActivityType.Kitesurfing:
      return getActivityTypeOverflow(InternalActivityType.Kitesurfing, category, KitingIcon);
    case InternalActivityType.Football:
      return getActivityTypeOverflow(InternalActivityType.Football, category, FootballIcon);
    case InternalActivityType.Basketball:
      return getActivityTypeOverflow(InternalActivityType.Basketball, category, BasketballIcon);
    case InternalActivityType.Volleyball:
      return getActivityTypeOverflow(InternalActivityType.Volleyball, category, VolleyballIcon);
    case InternalActivityType.Tennis:
      return getActivityTypeOverflow(InternalActivityType.Tennis, category, TennisIcon);
    case InternalActivityType.Badminton:
      return getActivityTypeOverflow(InternalActivityType.Badminton, category, BadmintonIcon);
    case InternalActivityType.Handball:
      return getActivityTypeOverflow(InternalActivityType.Handball, category, HandballIcon);
    case InternalActivityType.Bouldern:
      return getActivityTypeOverflow(InternalActivityType.Bouldern, category, BoulderIcon);
    case InternalActivityType.PoleDance:
      return getActivityTypeOverflow(InternalActivityType.PoleDance, category, PoleDanceIcon);
    case InternalActivityType.Golf:
      return getActivityTypeOverflow(InternalActivityType.Golf, category, GolfIcon);
    case InternalActivityType.SUP:
      return getActivityTypeOverflow(InternalActivityType.SUP, category, SUPIcon);
    case InternalActivityType.Sailing:
      return getActivityTypeOverflow(InternalActivityType.Sailing, category, SailingIcon);
    case InternalActivityType.Climbing:
      return getActivityTypeOverflow(InternalActivityType.Climbing, category, BoulderIcon);
    case InternalActivityType.HorsebackRiding:
      return getActivityTypeOverflow(InternalActivityType.HorsebackRiding, category, HorsebackRidingIcon);
    case InternalActivityType.MartialArts:
      return getActivityTypeOverflow(InternalActivityType.MartialArts, category, MartialArtsIcon);
    case InternalActivityType.Gymnastics:
      return getActivityTypeOverflow(InternalActivityType.Gymnastics, category, GymnasticsIcon);
    case InternalActivityType.InlineSkating:
      return getActivityTypeOverflow(InternalActivityType.InlineSkating, category, InlineSkatingIcon);
    case InternalActivityType.Frisbee:
      return getActivityTypeOverflow(InternalActivityType.Frisbee, category, OtherIcon);
    case InternalActivityType.Bowling:
      return getActivityTypeOverflow(InternalActivityType.Bowling, category, BowlingIcon);
    default:
      return getActivityTypeOverflow(InternalActivityType.ActivityNotAvailable, category, undefined);
  }
};

const getActivityColor = (category: string) => {
  switch (category as ActivityCategory) {
    case ActivityCategory.Endurance:
      return '#000078';
    case ActivityCategory.Fitness:
      return '#FFAF05';
    case ActivityCategory.Freetime:
      return '#41C38C';
    case ActivityCategory.Watersports:
      return '#569BD7';
    case ActivityCategory.Ballsports:
      return '#B4004E';
    default:
      return '#808080';
  }
};

const getActivityTypeOverflow = (type: InternalActivityType, category: string, icon: ReactNode): ActivityType => {
  return {
    type: type,
    color: getActivityColor(category),
    icon: icon
  } as ActivityType;
};
