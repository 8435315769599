import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { FormControlStyled, SelectStyled } from './DropdownMenu.styles';

interface Props {
  placeholder: string;
  items: string[];
  onChangeValue: (e: string) => void;
}

export function DropdownMenu(props: Props) {
  const [item, setItem] = useState('');

  function handleChange(event: any) {
    setItem(event.target.value);
    props.onChangeValue(event.target.value);
  }

  function renderPlaceholderItem() {
    return (
      <MenuItem value="" disabled>
        {props.placeholder}
      </MenuItem>
    );
  }

  function renderDropdownItems() {
    return props.items.map((item: string) => {
      return (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      );
    });
  }

  function renderContent() {
    return (
      <FormControlStyled>
        <SelectStyled value={item} defaultValue={''} displayEmpty onChange={handleChange}>
          {renderPlaceholderItem()}
          {renderDropdownItems()}
        </SelectStyled>
      </FormControlStyled>
    );
  }

  return <div>{renderContent()}</div>;
}
