import React from 'react';
import { SliderStyled, SliderTextWrapper, SliderWrapper, SliderInput } from './DurationSlider.style';

interface Props {
  duration: number;
  onChange?: (value: number | number[]) => void;
}

const marks = [
  {
    value: 60,
    label: '1h'
  },
  {
    value: 120,
    label: '2h'
  },
  {
    value: 180,
    label: '3h'
  },
  {
    value: 240,
    label: '4h'
  }
];

export const DurationSlider: React.FC<Props> = ({ duration, onChange }) => {
  return (
    <SliderWrapper>
      <SliderStyled
        defaultValue={0}
        min={5}
        max={240}
        track={'normal'}
        step={5}
        marks={marks}
        valueLabelDisplay="auto"
        onChange={(event, value) => onChange?.(value)}
        value={duration}
      />
      <SliderTextWrapper>
        {
          <SliderInput
            value={duration.toFixed(0)}
            onChange={(event) => onChange?.(Number(event.target.value))}
            inputProps={{
              step: 1,
              min: 1,
              max: 480,
              type: 'number'
            }}
          />
        }{' '}
        Min.
      </SliderTextWrapper>
    </SliderWrapper>
  );
};
