import React from 'react';
import { UnauthenticatedTemplate, AuthenticatedTemplate } from '@azure/msal-react';

import { ContentWrapper, PageWrapper } from './PageTemplate.style';
import { NavigationBar } from '../NavigationBar/NavigationBar';
import { AppBar } from '../AppBar/AppBar';
import { Page } from '../../models/Page';
import { ContentRefresher } from './ContentRefresher';
import { useAutoLogin } from '../../hooks/useAutoLogin';
import { WelcomeScreen } from '../WelcomeScreen/WelcomeScreen';
import { LoadingProgressBar } from '../LoadingProgressBar/LoadingProgressBar';
import { RefetchConfig } from '../UserProvider/UserProvider';

interface PageTemplateProps {
  isLandingPage?: boolean;
  showBackButton?: boolean;
  title: string;
  currentPage?: Page;
  disableRefresh?: boolean;
  isPaddingTop?: boolean;
  refetchConfig?: Partial<RefetchConfig>;
}

export const PageTemplate: React.FC<PageTemplateProps> = ({
  isLandingPage,
  showBackButton,
  title,
  currentPage = Page.TEAM_FEED,
  children,
  disableRefresh = false,
  isPaddingTop,
  refetchConfig
}) => {
  useAutoLogin();

  return (
    <>
      <UnauthenticatedTemplate>
        <WelcomeScreen />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <PageWrapper>
          <AppBar title={title} isLandingPage={isLandingPage} showBackButton={showBackButton} />
          <LoadingProgressBar />
          <ContentRefresher disableRefresh={disableRefresh} refetchConfig={refetchConfig}>
            <ContentWrapper isPaddingTop={isPaddingTop}>{children}</ContentWrapper>
          </ContentRefresher>
          <NavigationBar currentPage={currentPage} />
        </PageWrapper>
      </AuthenticatedTemplate>
    </>
  );
};
