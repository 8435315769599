import React from 'react';
import { UseQueryResult } from 'react-query';

export interface DisplayQueryProps<Q> {
  query?: UseQueryResult<Q>;
  loadingElement: JSX.Element;
  errorElement: JSX.Element;
  noDataElement: JSX.Element;
}

export function DisplayQuery<Q>({
  query,
  loadingElement,
  errorElement,
  noDataElement,
  children
}: React.PropsWithChildren<DisplayQueryProps<Q>>): JSX.Element {
  if (!query || query.isLoading) {
    return loadingElement;
  }
  if (query.error) {
    return errorElement;
  }
  if (!query.isSuccess || query.data === undefined || (Array.isArray(query.data) && query.data.length === 0)) {
    return noDataElement;
  }

  return <>{children}</>;
}
