import { Container, Grid } from '@material-ui/core';

import StatisticsCell from '../../components/ActivityHistory/StatisticsCell';
import ActivityHistory from '../../components/ActivityHistory/ActivityHistory';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import React from 'react';
import { StatusContainer, StatusContent } from '../TeamFeed/TeamFeedPage.style';
import { Activity } from '../../models/Activity';
import { Page } from '../../models/Page';
import { RefetchConfig, useUserActivities } from '../../components/UserProvider/UserProvider';
import { DisplayQuery } from '../../components/DisplayQuery/DisplayQuery';

export const ActivityHistoryPage: React.FC = () => {
  const userActivitiesQuery = useUserActivities();

  const refetchConfig: Partial<RefetchConfig> = {
    isUserActivities: true
  };

  const getOverallDuration = (data: Activity[]) => {
    if (data.length === 0) {
      return 0;
    }

    return data.map((activity) => activity.duration).reduce((prevActivity, activity) => prevActivity + activity);
  };

  const getOverallFitnessPoints = (data: Activity[]) => {
    if (data.length === 0) {
      return 0;
    }

    return data.map((activity) => activity.fitnessPoints).reduce((prevActivity, activity) => prevActivity + activity);
  };

  const renderLoadingInfo = () => (
    <StatusContainer container direction={'column'}>
      <StatusContent>Loading activities ...</StatusContent>
    </StatusContainer>
  );

  const renderError = () => (
    <StatusContainer container direction={'column'}>
      <StatusContent>Activities couldn't be loaded :(</StatusContent>
    </StatusContainer>
  );

  const renderNoDataInfo = () => (
    <StatusContainer container direction={'column'}>
      <StatusContent>No activities found ...</StatusContent>
    </StatusContainer>
  );

  const renderContent = (activities: Activity[] = []) => (
    <>
      <Container>
        <Grid container>
          <StatisticsCell
            overallDuration={getOverallDuration(activities)}
            overallPoints={getOverallFitnessPoints(activities)}
          />
        </Grid>
      </Container>
      <ActivityHistory data={activities} />
    </>
  );

  function render() {
    return (
      <DisplayQuery
        query={userActivitiesQuery}
        loadingElement={renderLoadingInfo()}
        errorElement={renderError()}
        noDataElement={renderNoDataInfo()}
      >
        {renderContent(userActivitiesQuery?.data)}
      </DisplayQuery>
    );
  }

  return (
    <PageTemplate title={'My Activities'} currentPage={Page.HISTORY} refetchConfig={refetchConfig}>
      {render()}
    </PageTemplate>
  );
};
