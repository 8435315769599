export interface Ranking {
  userName: string;
  fitnessPoints: number;
}

export type Rank = 0 | 1 | 2;

export function isRank(data: unknown): data is Rank {
  return typeof data === 'number' && (data === 0 || data === 1 || data === 2);
}
