import React from 'react';
import { SwitchStyled, SwitchWrapper } from './Switch.style';

interface Props {
  checked: boolean;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const Switch: React.FC<Props> = ({ checked, name, onChange, children }) => {
  return (
    <SwitchWrapper>
      {children}
      <SwitchStyled checked={checked} onChange={onChange} name={name} />
    </SwitchWrapper>
  );
};
