import { Typography } from '@material-ui/core';
import React from 'react';

import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { Container, HDivider, UserImage, UserInformation } from './ProfilePage.style';
import { FactSubline } from '../../globalStyle/FontStyles';
import { LogoutButton } from '../../components/Login/LogoutButton';
import { RefetchConfig, useAccount, useProfilePicture } from '../../components/UserProvider/UserProvider';
import { DisplayQuery } from '../../components/DisplayQuery/DisplayQuery';

export default function ProfilePage() {
  const accountQuery = useAccount();
  const profilePictureQuery = useProfilePicture();

  const refetchConfig: Partial<RefetchConfig> = {
    isAccount: true
  };

  function renderFitnessPoints() {
    return (
      <DisplayQuery
        query={accountQuery}
        loadingElement={<FactSubline> Loading points...</FactSubline>}
        errorElement={<FactSubline>Points couldn't be loaded :( </FactSubline>}
        noDataElement={<FactSubline>No data available :( </FactSubline>}
      >
        <Typography variant="h5">{(accountQuery?.data?.fitnessPoints || 0).toFixed(2)} pts.</Typography>
      </DisplayQuery>
    );
  }

  function content() {
    return (
      <Container>
        <UserImage src={profilePictureQuery?.data} />
        <UserInformation>
          <Typography variant="h6">{accountQuery?.data?.displayName}</Typography>
          {renderFitnessPoints()}
        </UserInformation>
        <HDivider />
        <div style={{ marginTop: 10 }}>
          <LogoutButton />
        </div>
      </Container>
    );
  }

  return (
    <PageTemplate title={'My Profile'} showBackButton isPaddingTop={true} refetchConfig={refetchConfig}>
      {content()}
    </PageTemplate>
  );
}
