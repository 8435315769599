import styled from '@emotion/styled';
import Card from '@material-ui/core/Card';
import { Favorite } from '@material-ui/icons';
import { Colors } from '../../globalStyle/Colors';

interface IconProps {
  color: string;
}

export const FeedCard = styled(Card)`
  margin-bottom: 15px;
  padding: 16px;
  display: grid;
  grid-template-columns: min-content auto min-content;
`;

export const FeedCardHeader = styled.div`
  display: grid;
  grid-template-columns: min-content auto min-content;
`;

export const Message = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: black;
`;

export const TimeStamp = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  color: lightGray;
`;

export const ActivityDate = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  color: lightGray;
`;

export const GroupIcon = styled.div<IconProps>`
  justify-self: right;
  margin-right: 1px;
  svg {
    color: ${(props) => props.color};
    fill: ${(props) => props.color};
    width: 24px;
    height: 24px;
  }
`;

export const LikeButtonContainer = styled.div`
  justify-self: right;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 1fr;
  place-items: center;
`;

export const LikeButton = styled.div`
  display: grid;
  place-items: center;
`;

export const RedLikeIcon = styled(Favorite)`
  color: ${Colors.SignalRed};
`;
