import styled from '@emotion/styled';
import { AppBar, BottomNavigation, BottomNavigationAction, Toolbar } from '@material-ui/core';

import { Colors } from '../../globalStyle/Colors';
import { ReactComponent as FeedIcon } from '../../assets/icons/team_feed_black_24dp.svg';

interface IconProps {
  isActive?: boolean;
}

export const PageWrapper = styled.div`
  height: 100vh;
`;

export const AppBarStyled = styled(AppBar)`
  background-color: ${Colors.White} !important;
  box-shadow: none !important;
  border-bottom: 1px solid ${Colors.Silver};
`;

export const ToolbarStyled = styled(Toolbar)`
  min-height: 65px !important;
`;

export const BottomNavigationStyled = styled(BottomNavigation)`
  width: 100%;
  height: 65px !important;
  position: fixed;
  bottom: 0;
  padding-bottom: 0;
  padding-bottom: env(safe-area-inset-bottom, 0);
  box-shadow: 0 5px 25px ${Colors.Silver};
`;

export const BottomNavigationActionStyled = styled(BottomNavigationAction)`
  color: grey;
  min-width: 70px;
  padding: 6px 7px 8px;

  & .MuiBottomNavigationAction-label {
    font-size: 12px;
  }

  span {
    color: black;
  }
`;

export const IconWrapper = styled.div<IconProps>`
  svg {
    fill: ${(props) => (props.isActive ? 'black' : '#969696')};
  }
`;

export const FeedIconStyled = styled(FeedIcon)`
  width: 24px;
  height: 24px;
`;
