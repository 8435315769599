import { Avatar, Container, Grid, SvgIcon, Typography } from '@material-ui/core';
import React from 'react';
import { ActivityType } from '../../models/ActivityType';
import { ActivityCellTextContainer, ActivityCellItem, AdditionalPointsText, StyledLink } from './ActivityCell.style';
import { ActivityCategory } from '../../globalStyle/FontStyles';

interface Props {
  id: string;
  activityType: ActivityType;
  name: string;
  date: string;
  duration: number;
  points: number;
}

export default function ActivityCell(props: Props) {
  function renderAvatar() {
    return props.activityType.icon === undefined ? (
      <Typography variant="body2">N/A</Typography>
    ) : (
      <SvgIcon component={props.activityType.icon} />
    );
  }
  return (
    <StyledLink to={`/activity/${props.id}`}>
      <Container>
        <ActivityCellItem item>
          <Grid alignItems="center" container spacing={2}>
            <Grid item>
              <Avatar style={{ backgroundColor: props.activityType.color }}>{renderAvatar()}</Avatar>
            </Grid>
            <ActivityCellTextContainer item xs={9}>
              <Grid container>
                <Grid item xs={7} sm lg md xl>
                  <ActivityCategory>{props.name}</ActivityCategory>
                </Grid>
                <Grid item>
                  <AdditionalPointsText align="justify" variant="subtitle2">
                    +{props.points.toFixed(2)} Pts.
                  </AdditionalPointsText>
                </Grid>
              </Grid>
              <Typography variant="subtitle2">
                {props.date} - {props.duration} Min.
              </Typography>
            </ActivityCellTextContainer>
          </Grid>
        </ActivityCellItem>
      </Container>
    </StyledLink>
  );
}
