import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import { Colors } from '../../globalStyle/Colors';

export const StatusContainer = styled(Grid)`
  align-items: center;
  justify-content: center;
  min-height: 80vh;
`;

export const StatusContent = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.Grey};
`;
