import React from 'react';
import { WelcomeScreenContainer, WelcomeScreenFooter, WelcomeMessage } from './WelcomeScreen.style';
import { ReactComponent as Logo } from '../../assets/images/uxma.logo.svg';

export const WelcomeScreen: React.FC = () => (
  <WelcomeScreenContainer>
    <WelcomeMessage>Together for a good cause and for your own health</WelcomeMessage>
    <WelcomeScreenFooter>
      <Logo />
    </WelcomeScreenFooter>
  </WelcomeScreenContainer>
);
