import { useMutation } from 'react-query';
import moment from 'moment';
import { updateActivity } from '../facades/BackendFacade';
import { useRefetch } from '../components/UserProvider/UserProvider';
import { useHistory } from 'react-router-dom';
import { ActivityForm } from './useActivityForm';
import { Activity } from '../models/Activity';

export function useUpdateActivity(activityId?: string) {
  const refetch = useRefetch();
  const history = useHistory();

  const { mutate, isLoading } = useMutation(updateActivity, {
    onSuccess: () => history.goBack(),
    onSettled: () => void refetch()
  });

  function patchActivity(activityForm: ActivityForm) {
    const activityData: Partial<Activity> = {
      timestamp: moment(activityForm.date).valueOf(),
      duration: activityForm.duration,
      teamActivity: activityForm.isTeamActivity
    };

    if (activityId !== undefined) {
      mutate({
        activityId,
        activity: activityData
      });
    }
  }

  return {
    patchActivity,
    isLoading
  };
}
