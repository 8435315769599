import React from 'react';
import {
  ActivityIcon,
  ButtonContainer,
  DateText,
  DescriptionText,
  FeedbackText,
  HeartIcon,
  HeartIconContainer,
  PageBackground
} from './ActivityResultPage.style';
import moment from 'moment';
import 'moment/locale/de';
import ActionButton from '../../components/Button/ActionButton';
import { ReactComponent as CheckIcon } from '../../assets/icons/done_black_24dp.svg';
import { SvgIcon } from '@material-ui/core';
import { getActivityType } from '../../models/ActivityType';
import { ReactComponent as Heart } from '../../assets/icons/favorite_black_24dp.svg';
import { useNewActivity } from '../../components/UserProvider/UserProvider';
import { useHistory } from 'react-router-dom';
import { CenteredProgress } from '../../components/CenteredProgress/CenteredProgress';

export const ActivityResultPage: React.FC = () => {
  const { newActivity, setNewActivity } = useNewActivity();
  const history = useHistory();

  moment.locale('en');

  function handleDoneClick() {
    setNewActivity(undefined);
    history.push('/activityhistory');
  }

  if (!newActivity) {
    return <CenteredProgress />;
  }

  const activityType = getActivityType(newActivity.type, newActivity.category);
  const date = moment(newActivity.timestamp).format('dd, Do MMMM');

  return (
    <PageBackground color={activityType.color}>
      <div>
        <HeartIconContainer>
          <HeartIcon>
            <SvgIcon component={Heart} />
          </HeartIcon>
          <ActivityIcon color={activityType.color}>{<SvgIcon component={activityType.icon} />}</ActivityIcon>
        </HeartIconContainer>
        <DateText>
          {date} – {newActivity.duration} Min.
        </DateText>
        <FeedbackText>
          Great job! You have collected +{newActivity.fitnessPoints.toFixed(2)} Points for {newActivity.type}.
        </FeedbackText>
        <DescriptionText>{newActivity.description}</DescriptionText>
        <ButtonContainer>
          <ActionButton white icon={<SvgIcon component={CheckIcon} />} onClick={handleDoneClick}>
            Done
          </ActionButton>
        </ButtonContainer>
      </div>
    </PageBackground>
  );
};
