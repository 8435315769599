import React, { ReactNode } from 'react';

import { ActionButtonStyled, ActionButtonWrapper } from './ActionButton.style';

interface Props {
  children?: ReactNode;
  icon?: ReactNode;
  href?: string;
  onClick?: () => any;
  disabled?: boolean;
  hasMargin?: boolean;
  white?: boolean;
}

export default function ActionButton(props: Props) {
  return (
    <ActionButtonWrapper hasMargin={props.hasMargin}>
      <ActionButtonStyled
        white={props.white}
        disabled={props.disabled}
        onClick={props.onClick}
        href={props.href}
        startIcon={props.icon}
      >
        {props.children}
      </ActionButtonStyled>
    </ActionButtonWrapper>
  );
}
