import { Link } from 'react-router-dom';
import { Page } from '../../models/Page';
import { BottomNavigationActionStyled, IconWrapper } from './NavigationBar.style';
import React from 'react';
import { BottomNavigationAction } from '@material-ui/core';

interface Props {
  url: string;
  label: string;
  page: Page;
  activePage: Page;
}

const BottomNavigationTab = BottomNavigationActionStyled as typeof BottomNavigationAction;

export const NavigationTab: React.FC<Props> = ({ url, label, page, activePage, children }) => {
  return (
    <BottomNavigationTab
      component={Link}
      to={url}
      value={page}
      label={label}
      showLabel={true}
      icon={<IconWrapper isActive={page === activePage}>{children}</IconWrapper>}
    />
  );
};
