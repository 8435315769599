import React, { useContext, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { noop } from 'lodash';

import { Account } from '../../models/Account';
import {
  getAccount,
  getAllActivities,
  getAllFitnessPoints,
  getRanking,
  getUserActivities
} from '../../facades/BackendFacade';
import { getPhoto } from '../../facades/MsGraphFacade';
import { Activity, ActivityEnhancedWithLikes } from '../../models/Activity';
import { TotalFitnessPoints } from '../../models/TotalFitnessPoints';
import { Ranking } from '../../models/Ranking';

export interface UserData {
  account?: UseQueryResult<Account>;
  profilePicture?: UseQueryResult<string>;
  allActivities?: UseQueryResult<ActivityEnhancedWithLikes[]>;
  userActivities?: UseQueryResult<Activity[]>;
  allFitnessPoints?: UseQueryResult<TotalFitnessPoints>;
  ranking?: UseQueryResult<Ranking[]>;
  newActivity: Activity | undefined;
  setNewActivity: (activity: Activity | undefined) => void;
}

interface UseActivityResult {
  isLoadingActivity?: boolean;
  activity?: Activity;
  errorLoadingActivity?: string | undefined;
}

export interface RefetchConfig {
  isAllActivities: boolean;
  isUserActivities: boolean;
  isAccount: boolean;
  isAllFitnessPoints: boolean;
  isRanking: boolean;
}

const UserContext = React.createContext<UserData>({
  newActivity: undefined,
  setNewActivity: noop
});

export function useAccount() {
  const userData = useContext(UserContext);
  return userData.account;
}

export function useProfilePicture() {
  const userData = useContext(UserContext);
  return userData.profilePicture;
}

export function useAllActivities() {
  const userData = useContext(UserContext);
  return userData.allActivities;
}

export function useUserActivities() {
  const userData = useContext(UserContext);
  return userData.userActivities;
}

export function useActivity(activityId?: string): UseActivityResult {
  const query = useUserActivities();

  if (!query || query.isLoading) {
    return { isLoadingActivity: true };
  }
  if (
    query.error ||
    !query.isSuccess ||
    query.data === undefined ||
    activityId === undefined ||
    (Array.isArray(query.data) && query.data.length === 0)
  ) {
    return { errorLoadingActivity: 'Failed to load activity.' };
  }

  return {
    activity: query.data.find((activity) => activity.activityId === activityId)
  };
}

export function useAllFitnessPoints() {
  const userData = useContext(UserContext);
  return userData.allFitnessPoints;
}

export function useRanking() {
  const userData = useContext(UserContext);
  return userData.ranking;
}

export function useNewActivity() {
  const userData = useContext(UserContext);
  return {
    newActivity: userData.newActivity,
    setNewActivity: userData.setNewActivity
  };
}

function completeRefetchConfig(config?: Partial<RefetchConfig>): RefetchConfig {
  if (config === undefined) {
    return {
      isAllActivities: true,
      isUserActivities: true,
      isAccount: true,
      isAllFitnessPoints: true,
      isRanking: true
    };
  }

  return {
    isAllActivities: config.isAllActivities ?? false,
    isUserActivities: config.isUserActivities ?? false,
    isAccount: config.isAccount ?? false,
    isAllFitnessPoints: config.isAllFitnessPoints ?? false,
    isRanking: config.isRanking ?? false
  };
}

async function doNothing() {}
function buildRefetch<T>(shouldRefetch: boolean, query?: UseQueryResult<T>): Promise<any> {
  return shouldRefetch && query ? query.refetch() : doNothing();
}

export function useRefetch(config?: Partial<RefetchConfig>): any {
  const { isAllActivities, isUserActivities, isAccount, isAllFitnessPoints, isRanking } = completeRefetchConfig(config);
  const { allActivities, userActivities, account, allFitnessPoints, ranking } = useContext(UserContext);

  return async () =>
    Promise.all([
      buildRefetch(isAllActivities, allActivities),
      buildRefetch(isUserActivities, userActivities),
      buildRefetch(isAccount, account),
      buildRefetch(isAllFitnessPoints, allFitnessPoints),
      buildRefetch(isRanking, ranking)
    ]);
}

export function useIsLoading() {
  const { allActivities, userActivities, account, allFitnessPoints, ranking } = useContext(UserContext);

  return (
    allActivities?.isLoading ||
    userActivities?.isLoading ||
    account?.isLoading ||
    allFitnessPoints?.isLoading ||
    ranking?.isLoading
  );
}

export const UserProvider: React.FC = ({ children }) => {
  const account = useQuery('account ', getAccount);
  const profilePicture = useQuery('photo', getPhoto);
  const allActivities = useQuery('activities list', getAllActivities);
  const userActivities = useQuery('activities user', getUserActivities);
  const allFitnessPoints = useQuery('fitnessPoints', getAllFitnessPoints);
  const ranking = useQuery('ranking', getRanking);

  const [newActivity, setNewActivity] = useState<Activity | undefined>(undefined);

  const userContext: UserData = {
    account,
    profilePicture,
    allActivities,
    userActivities,
    allFitnessPoints,
    newActivity,
    setNewActivity,
    ranking
  };

  return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>;
};
