import { SvgIcon, Typography } from '@material-ui/core';
import { ReactComponent as ScheduleIcon } from '../../assets/icons/schedule_black_24dp.svg';
import { ReactComponent as TrophyIcon } from '../../assets/icons/emoji_events_black_24dp.svg';
import { StatisticsContainer, StatisticsContainerItem } from './StatisticsCell.style';
import { FactHeadline } from '../../globalStyle/FontStyles';

interface Props {
  overallDuration?: number;
  overallPoints?: number;
}

export default function StatisticsCell(props: Props) {
  return (
    <StatisticsContainer container>
      <StatisticsContainerItem item xs={6}>
        <SvgIcon component={ScheduleIcon} />
        <FactHeadline>{props.overallDuration || 0} Min.</FactHeadline>
        <Typography variant="subtitle2">overall</Typography>
      </StatisticsContainerItem>
      <StatisticsContainerItem item xs={6}>
        <SvgIcon component={TrophyIcon} />
        <FactHeadline>{(props.overallPoints || 0).toFixed(2)} Pts.</FactHeadline>
        <Typography variant="subtitle2">overall</Typography>
      </StatisticsContainerItem>
    </StatisticsContainer>
  );
}
