export const Colors = {
  White: '#FFFFFF',
  Alabaster: '#FAFAFA',
  Silver: '#C8C8C8',
  LightGrey: '#969696',
  Grey: '#919191',
  DoveGrey: '#646464',
  Black: '#000000',
  SignalRed: '#F00032',
  DarkBlue: '#000078',
  Yellow: '#FFAF05',
  Green: '#28BE8C',
  LightBlue: '#569BD7',
  DarkPink: '#B4004E',
  LightRed: '#ff456c'
};
