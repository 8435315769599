import React from 'react';
import { Container } from '@material-ui/core';
import moment from 'moment';

import { TeamFeedCard } from './TeamFeedCard';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { StatusContainer, StatusContent } from './TeamFeedPage.style';
import { ActivityEnhancedWithLikes } from '../../models/Activity';
import { RefetchConfig, useAllActivities } from '../../components/UserProvider/UserProvider';

export const TeamFeedPage: React.FC = () => {
  const allActivitiesQuery = useAllActivities();
  const refetchConfig: Partial<RefetchConfig> = {
    isAllActivities: true
  };

  const renderLoadingInfo = () => (
    <StatusContainer container direction={'column'}>
      <StatusContent>Loading feeds ...</StatusContent>
    </StatusContainer>
  );

  const renderError = () => (
    <StatusContainer container direction={'column'}>
      <StatusContent>Feeds couldn't be loaded :(</StatusContent>
    </StatusContainer>
  );

  const renderNoDataInfo = () => (
    <StatusContainer container direction={'column'}>
      <StatusContent>There are no feeds.</StatusContent>
    </StatusContainer>
  );

  const formatTimeStamp = (timestamp: number) => {
    moment.locale('en');
    return moment(timestamp).fromNow();
  };
  const formatDate = (date: number) => {
    moment.locale('en');
    return moment(date).format('DD/MM/YYYY');
  };

  const sortActivities = (unsortedActivities: ActivityEnhancedWithLikes[]) => {
    return unsortedActivities.sort((a, b) => b.postedAt - a.postedAt);
  };

  const renderActivities = (activities: ActivityEnhancedWithLikes[]) => (
    <Container>
      {activities.map((activity, index) => (
        <TeamFeedCard
          key={`${index}${activity.postedAt}`}
          creationDate={`${formatTimeStamp(activity.postedAt)}`}
          activityDate={`${formatDate(activity.timestamp)}`}
          message={`did ${activity.type} for ${activity.duration} Min.`}
          item={activity}
        />
      ))}
    </Container>
  );

  function renderContent() {
    if (!allActivitiesQuery || allActivitiesQuery.isLoading) {
      return renderLoadingInfo();
    }

    const { error, isSuccess, data: activities } = allActivitiesQuery;

    if (error) {
      return renderError();
    }
    if (!isSuccess || activities === undefined || activities.length === 0) {
      return renderNoDataInfo();
    }
    return renderActivities(sortActivities(activities));
  }

  return (
    <PageTemplate title={'Team Feed'} isLandingPage isPaddingTop={true} refetchConfig={refetchConfig}>
      {renderContent()}
    </PageTemplate>
  );
};
