import React from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { RefetchConfig, useRefetch } from '../UserProvider/UserProvider';

interface PullToRefreshProps {
  children: JSX.Element;
  disableRefresh: boolean;
  refetchConfig?: Partial<RefetchConfig>;
}

export const ContentRefresher: React.FC<PullToRefreshProps> = ({ refetchConfig, disableRefresh, children }) => {
  const refetch = useRefetch(refetchConfig);

  return (
    <PullToRefresh isPullable={!disableRefresh} onRefresh={refetch} pullingContent={''} pullDownThreshold={50}>
      {children}
    </PullToRefresh>
  );
};
