import moment from 'moment';
import React from 'react';
import { Activity } from '../../models/Activity';
import { getActivityType } from '../../models/ActivityType';
import ActivityCell from './ActivityCell';
import { Divider } from '../Divider/Divider';

interface Props {
  data: Activity[];
}

export default function ActivityHistory({ data }: Props) {
  function getTimestampForActivity(activity: Activity) {
    return activity.timestamp ?? activity.postedAt;
  }

  function sortActivitiesDesc(previousActivity: Activity, nextActivity: Activity) {
    return getTimestampForActivity(nextActivity) - getTimestampForActivity(previousActivity);
  }

  return (
    <>
      {data
        .filter((activity) => activity.category.length > 0)
        .sort(sortActivitiesDesc)
        .map((activity: Activity) => (
          <>
            <Divider />
            <ActivityCell
              key={activity.activityId}
              id={activity.activityId}
              name={activity.type}
              date={moment(getTimestampForActivity(activity)).format('ddd, Do MMMM')}
              duration={activity.duration}
              points={activity.fitnessPoints}
              activityType={getActivityType(activity.type, activity.category)}
            />
          </>
        ))}
    </>
  );
}
