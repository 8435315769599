import React, { useState } from 'react';

import { BottomNavigationStyled, FeedIconStyled } from './NavigationBar.style';

import { ReactComponent as NewActivityIcon } from '../../assets/icons/add_circle_outline_black_24dp.svg';
import { ReactComponent as InformationIcon } from '../../assets/icons/info_black_24dp.svg';
import { ReactComponent as PollIcon } from '../../assets/icons/poll_black_24dp.svg';
import { Page } from '../../models/Page';
import { NavigationTab } from './NavigationTab';

interface NavigationBarProps {
  currentPage: Page;
}

export const NavigationBar: React.FC<NavigationBarProps> = ({ currentPage }) => {
  const [activePage, setActivePage] = useState<Page>(currentPage);

  function handleOnChange(value: Page) {
    setActivePage(value);
  }

  return (
    <BottomNavigationStyled showLabels value={activePage} onChange={(_, value: Page) => handleOnChange(value)}>
      <NavigationTab url="/" label="Team-Feed" page={Page.TEAM_FEED} activePage={activePage}>
        <FeedIconStyled />
      </NavigationTab>
      <NavigationTab url="/newActivity" label="New Activity" page={Page.NEW_ACTIVITY} activePage={activePage}>
        <NewActivityIcon />
      </NavigationTab>
      <NavigationTab url="/activityhistory" label="My History" page={Page.HISTORY} activePage={activePage}>
        <PollIcon />
      </NavigationTab>
      <NavigationTab url="/info" label="Information" page={Page.INFORMATION} activePage={activePage}>
        <InformationIcon />
      </NavigationTab>
    </BottomNavigationStyled>
  );
};
