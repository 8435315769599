import { useState } from 'react';
import { Activity } from '../models/Activity';

export interface ActivityForm {
  duration: number;
  type: string;
  date: Date;
  isTeamActivity: boolean;
  isPublicActivity: boolean;
}

export function useActivityForm(activity: Partial<Activity> = {}) {
  const initialForm: ActivityForm = {
    duration: activity.duration ?? 0,
    type: activity.type ?? '',
    date: activity.timestamp !== undefined ? new Date(activity.timestamp) : new Date(),
    isTeamActivity: activity.teamActivity ?? false,
    isPublicActivity: activity.publicActivity ?? true
  };

  const [duration, setDuration] = useState(initialForm.duration);
  const [type, setType] = useState(initialForm.type);
  const [date, setDate] = useState(initialForm.date);
  const [isTeamActivity, setIsTeamActivity] = useState(initialForm.isTeamActivity);
  const [isPublicActivity, setIsPublicActivity] = useState(initialForm.isPublicActivity);

  const activityForm: ActivityForm = {
    duration,
    type,
    date,
    isTeamActivity,
    isPublicActivity
  };

  const update = (activity: Partial<Activity> = {}) => {
    if (activity.duration !== undefined) {
      setDuration(activity.duration);
    }
    if (activity.type !== undefined) {
      setType(activity.type);
    }
    if (activity.timestamp !== undefined) {
      setDate(new Date(activity.timestamp));
    }
    if (activity.teamActivity !== undefined) {
      setIsTeamActivity(activity.teamActivity);
    }
    if (activity.publicActivity !== undefined) {
      setIsPublicActivity(activity.publicActivity);
    }
  };

  return {
    activityForm,
    setDuration,
    setType,
    setDate,
    setIsTeamActivity,
    setIsPublicActivity,
    update
  };
}
