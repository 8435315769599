import styled from '@emotion/styled';
import { Colors } from '../../globalStyle/Colors';
import backgroundWelcomeScreen from '../../assets/images/backgroundLandingPage.png';

export const WelcomeScreenContainer = styled.div`
  background-image: url(${backgroundWelcomeScreen});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Colors.Black};
`;

export const WelcomeMessage = styled.div`
  padding-right: 25px;
  text-align: right;
  color: ${Colors.White};
  font-weight: 800;
  font-size: 36px;
`;

export const WelcomeScreenFooter = styled.div`
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 30px;
`;
