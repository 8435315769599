import styled from '@emotion/styled';
import { Switch } from '@material-ui/core';
import { Colors } from '../../globalStyle/Colors';

export const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SwitchStyled = styled(Switch)`
  & .MuiSwitch-colorSecondary.Mui-checked {
    color: ${Colors.White};
  }

  & .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${Colors.Green};
  }

  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    opacity: 1;
  }
`;
