import styled from '@emotion/styled';
import { FormControl, Select } from '@material-ui/core';

export const FormControlStyled = styled(FormControl)`
  min-width: 100%;
  margin-top: 20px;
`;

export const SelectStyled = styled(Select)`
  font-size: 20px;
`;
