import React, { Fragment } from 'react';

import { RankingGridContainer, RankingName, RankingIconContainer, RankingPoints } from './TopThreeRanking.style';
import { ReactComponent as BronzeIcon } from '../../assets/icons/ranking_bronze.svg';
import { ReactComponent as SilverIcon } from '../../assets/icons/ranking_silver.svg';
import { ReactComponent as GoldIcon } from '../../assets/icons/ranking_gold.svg';
import { isRank, Rank, Ranking } from '../../models/Ranking';

interface Props {
  ranking: Ranking[];
}

export const RankingGrid: React.FC<Props> = ({ ranking }) => {
  function getRankIcon(rank: Rank) {
    switch (rank) {
      case 0:
        return <GoldIcon />;
      case 1:
        return <SilverIcon />;
      case 2:
        return <BronzeIcon />;
    }
  }

  return (
    <RankingGridContainer>
      {ranking.map(
        (item, rank) =>
          isRank(rank) && (
            <Fragment key={item.userName}>
              <RankingIconContainer>{getRankIcon(rank)}</RankingIconContainer>
              <RankingName>{item.userName}</RankingName>
              <RankingPoints>{item.fitnessPoints.toFixed(0)} pts.</RankingPoints>
            </Fragment>
          )
      )}
    </RankingGridContainer>
  );
};
