import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';

export const StatisticsContainer = styled(Grid)`
  padding: 20px 0px 10px 0px;
`;

export const StatisticsContainerItem = styled(Grid)`
  padding: 10px;
  text-align: center;
`;
