import { TotalFitnessPoints } from '../models/TotalFitnessPoints';
import { Account } from '../models/Account';
import { Activity, ActivityEnhancedWithLikes } from '../models/Activity';
import { ActivityCreation } from '../models/ActivityCreation';
import { RequestHandler } from './RequestHandler';
import { LikesInformation } from '../models/Like';
import { Ranking } from '../models/Ranking';

export async function getAllFitnessPoints(): Promise<TotalFitnessPoints> {
  return RequestHandler.get<TotalFitnessPoints>('/fitnessPoints');
}

export async function getAccount(): Promise<Account> {
  return RequestHandler.get<Account>(`/account/`);
}

export async function getAllActivities(): Promise<ActivityEnhancedWithLikes[]> {
  return RequestHandler.get<ActivityEnhancedWithLikes[]>('/activities');
}

export async function getUserActivities(): Promise<Activity[]> {
  return RequestHandler.get<Activity[]>('/activities/user');
}

export async function getActivity(activityId: string): Promise<Activity> {
  return RequestHandler.get<Activity>(`/activities/${activityId}`);
}

export async function createActivity(activity: ActivityCreation): Promise<Activity> {
  return RequestHandler.post<Activity, ActivityCreation>('/activities', activity);
}

interface UpdateActivityParams {
  activityId: string;
  activity: Partial<Activity>;
}

export async function updateActivity({ activityId, activity }: UpdateActivityParams): Promise<Activity> {
  return RequestHandler.patch<Activity, Partial<Activity>>(`/activities/${activityId}`, activity);
}

export async function deleteActivity(activityId: string): Promise<void> {
  return RequestHandler.delete(`/activities/${activityId}`);
}

export async function addLike(activityId: string): Promise<LikesInformation> {
  return RequestHandler.post(`/likes/${activityId}`, {});
}

export async function removeLike(activityId: string): Promise<void> {
  return RequestHandler.delete(`/likes/${activityId}`);
}

export async function getRanking(): Promise<Ranking[]> {
  return RequestHandler.get('/ranking/top-three');
}
