import styled from '@emotion/styled';

interface ContentWrapperProps {
  isPaddingTop?: boolean;
}

export const PageWrapper = styled.div``;

export const ContentWrapper = styled.div<ContentWrapperProps>`
  padding-top: ${(props) => (props.isPaddingTop ? '10px' : '0')};
  padding-bottom: 70px;
`;
