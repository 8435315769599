import { useMutation } from 'react-query';
import moment from 'moment';
import { createActivity } from '../facades/BackendFacade';
import { Activity } from '../models/Activity';
import { useNewActivity, useRefetch } from '../components/UserProvider/UserProvider';
import { useHistory } from 'react-router-dom';
import { ActivityForm } from './useActivityForm';

export function useCreateActivity() {
  const { setNewActivity } = useNewActivity();
  const refetch = useRefetch();
  const history = useHistory();

  const { mutate: postCreateActivity, isLoading } = useMutation(createActivity, {
    onSuccess: handleSuccess,
    onError: (error) => console.log(error),
    onSettled: handleSettled
  });

  function handleSuccess(activity: Activity) {
    console.log('Successful created new activity.');
    setNewActivity(activity);
    history.push('/activityResult');
  }

  function handleSettled() {
    void refetch();
  }

  function postActivity(activityForm: ActivityForm) {
    const activityData = {
      publicActivity: activityForm.isPublicActivity,
      timestamp: moment(activityForm.date).valueOf(),
      duration: activityForm.duration,
      postedAt: moment().valueOf(),
      imageUrl: '',
      teamActivity: activityForm.isTeamActivity,
      type: activityForm.type
    };

    postCreateActivity(activityData);
  }

  return {
    postActivity,
    isLoading
  };
}
