import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import { css } from '@emotion/react';

import { Colors } from '../../globalStyle/Colors';

export interface ActionButtonWrapperProps {
  hasMargin?: boolean;
}

export const ActionButtonWrapper = styled.div<ActionButtonWrapperProps>`
  display: flex;
  justify-content: center;

  ${(props) =>
    props.hasMargin &&
    css`
      margin: 20px 0;
    `}
`;

interface ActionButtonProps {
  disabled?: boolean;
  white?: boolean;
}

export const ActionButtonStyled = styled(Button)<ActionButtonProps>`
  border-radius: 30px;
  border: none;
  background-color: ${Colors.SignalRed};
  box-shadow: none;
  height: 50px;
  max-width: max-content;
  box-shadow: 0px 10px 15px -15px rgba(0, 0, 0, 0.75);

  & .MuiButton-label {
    padding: 3px 23px 3px 23px;
    text-transform: uppercase;
    color: ${Colors.White};
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
  }

  &:hover {
    background: ${Colors.LightRed};
  }

  svg {
    fill: ${Colors.White};
  }

  ${(props) =>
    props.white &&
    css`
      background-color: ${Colors.White};

      & .MuiButton-label {
        color: ${Colors.Black};
      }

      &:hover {
        background: ${Colors.Silver};
      }

      svg {
        fill: ${Colors.Black};
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${Colors.Silver};

      & .MuiButton-label {
        color: ${Colors.Grey};
      }

      svg {
        fill: ${Colors.Grey};
      }
    `}
`;
