import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';
import React from 'react';

export const StyledCircularProgress = styled(CircularProgress)`
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
`;

export const CenteredProgress: React.FC = () => <StyledCircularProgress disableShrink size={80} />;
