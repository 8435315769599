import styled from '@emotion/styled';

interface StyledPageBackgroundProps {
  color: string;
}

export const PageBackground = styled.div<StyledPageBackgroundProps>`
  height: 100vh;
  width: 100%;
  background-color: ${(props) => props.color};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const DateText = styled.div`
  font: Roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: #ffffff;
  margin-top: 20px;
`;

export const FeedbackText = styled.div`
  font: Montserrat;
  font-weight: 800;
  font-size: 24px;
  line-height: 1.3;
  color: #ffffff;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
`;

export const DescriptionText = styled.div`
  font: Roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: #ffffff;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  display: inline-flex;
  align-self: flex-start;
  flex-direction: column;
`;

export const HeartIconContainer = styled.div`
  margin: 0 auto;
  width: 120px;
  height: 120px;
`;

export const HeartIcon = styled.div`
  svg {
    color: #ffffff;
    fill: #ffffff;
    height: 120px;
    width: 120px;
  }
  width: 120px;
  position: absolute;
`;

export const ActivityIcon = styled.div<StyledPageBackgroundProps>`
  margin: 0 auto;
  position: absolute;
  width: 120px;
  margin-top: 38px;
  svg {
    color: ${(props) => props.color};
    fill: ${(props) => props.color};
    height: 40px;
    width: 40px;
  }
`;
