import axios, { AxiosRequestConfig } from 'axios';
import { acquireToken } from './MsGraphFacade';

export class RequestHandler {
  private static readonly basePath = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

  static async get<R>(url: string): Promise<R> {
    const config = await RequestHandler.createAuthorizedConfig();
    const response = await axios.get<R>(`${RequestHandler.basePath}${url}`, config);
    return response.data;
  }

  static async post<R, D = any>(url: string, data: D): Promise<R> {
    const config = await this.createAuthorizedConfig();
    const response = await axios.post<R>(`${RequestHandler.basePath}${url}`, data, config);
    return response.data;
  }

  static async patch<R, D = any>(url: string, data: D): Promise<R> {
    const config = await this.createAuthorizedConfig();
    const response = await axios.patch<R>(`${RequestHandler.basePath}${url}`, data, config);
    return response.data;
  }

  static async delete(url: string): Promise<void> {
    const config = await this.createAuthorizedConfig();
    return axios.delete(`${RequestHandler.basePath}${url}`, config);
  }

  private static async createAuthorizedConfig(): Promise<AxiosRequestConfig> {
    const token = await acquireToken();
    return {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  }
}
