import styled from '@emotion/styled';
import { PageTitle } from '../../globalStyle/FontStyles';
import { Typography } from '@material-ui/core';
import { Colors } from '../../globalStyle/Colors';

export const TopThreeRankingContainer = styled.div`
  padding: 24px 16px 72px;
`;

export const RankingTitleContainer = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  justify-items: left;
  align-items: center;
  grid-gap: 8px;
  margin-bottom: 20px;
`;

export const RankingGridContainer = styled.div`
  display: grid;
  justify-items: left;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  grid-template-columns: min-content min-content 1fr;
`;

export const RankingName = styled(PageTitle)`
  white-space: nowrap;
  font-size: 14px;
`;

export const RankingIconContainer = styled.div`
  svg {
    width: 50px;
    height: 50px;
  }
`;

export const RankingPoints = styled(Typography)`
  color: ${Colors.Grey};
  font-weight: 600;
`;
