import styled from '@emotion/styled';
import { Avatar, Box, Divider } from '@material-ui/core';

export const Container = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const UserImage = styled(Avatar)`
  margin: 10px;
  width: 50px;
  height: 50px;
`;

export const UserInformation = styled(Container)`
  padding: 10px;
  text-align: center;
`;

export const HDivider = styled(Divider)`
  width: 100%;
`;
