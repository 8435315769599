import styled from '@emotion/styled';
import { CircularProgress, Divider } from '@material-ui/core';
import { css } from '@emotion/react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

export interface ComponentWrapperProps {
  hasMarginBottom?: boolean;
}

export const ComponentWrapper = styled.div<ComponentWrapperProps>`
  padding: 0 16px;

  ${(props) =>
    props.hasMarginBottom &&
    css`
      margin-bottom: 15px;
    `}
`;

interface DividerProps {
  hasMarginTop?: boolean;
}

export const EmptySpace = styled.div`
  height: 15px;
  width: 100%;
  clear: both;
`;

export const DividerStyled = styled(Divider)<DividerProps>`
  padding: 0 !important;
  width: 100%;

  ${(props) =>
    props.hasMarginTop &&
    css`
      margin-top: 20px;
    `}
`;

export const ButtonProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  margin-top: 20px;
  display: flex;
  background-color: lightgrey;
  border-radius: 30px;
  width: 100%;
  height: 30px;
  align-items: center;
`;

export const StyledToggleButton = styled(ToggleButton)({
  color: 'black',
  fontSize: '14.5px',
  border: 'none',
  borderRadius: '30px',
  textTransform: 'none',
  textAlign: 'center',
  height: '80%',
  width: '20%',
  flexGrow: 10,
  backgroundColor: 'lightgrey',
  borderWidth: '2px',

  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'black',
    backgroundColor: 'white',
    fontWeight: 'bold',
    borderRadius: '30px',
    borderWidth: '2px',
    width: '20%',
    margin: '3px'
  }
});
