import styled from '@emotion/styled';
import { AppBar, BottomNavigation, Toolbar } from '@material-ui/core';

import { Colors } from '../../globalStyle/Colors';

interface IconProps {
  isActive?: boolean;
}

export const PageWrapper = styled.div`
  height: 100vh;
`;

export const AppBarStyled = styled(AppBar)`
  background-color: ${Colors.White} !important;
  box-shadow: none !important;
  border-bottom: 1px solid ${Colors.Silver};
`;

export const ToolbarStyled = styled(Toolbar)`
  min-height: 65px !important;
`;

export const BottomNavigationStyled = styled(BottomNavigation)`
  width: 100%;
  height: 65px !important;
  position: fixed;
  bottom: 0;
  box-shadow: 0 5px 25px ${Colors.Silver};
`;

export const ContentWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 70px;
`;

export const IconWrapper = styled.div<IconProps>`
  svg {
    fill: ${(props) => (props.isActive ? 'black' : 'grey')};
  }
`;
