import { Avatar, SvgIcon, Typography } from '@material-ui/core';
import { FavoriteBorder as UnlikedIcon } from '@material-ui/icons';
import { useMutation } from 'react-query';

import {
    Message,
    TimeStamp,
    ActivityDate,
    FeedCard,
    GroupIcon,
    LikeButton,
    LikeButtonContainer,
    RedLikeIcon

} from './TeamFeedCard.style';
import { getActivityType } from '../../models/ActivityType';
import { ActivityEnhancedWithLikes } from '../../models/Activity';
import { addLike, removeLike } from '../../facades/BackendFacade';
import { useRefetch } from '../../components/UserProvider/UserProvider';
import { ReactComponent as GroupIconSvg } from '../../assets/icons/groups_black_24dp.svg';
import { LikeCountText, TeamFeedName } from '../../globalStyle/FontStyles';



interface CardProps {
  creationDate: string;
  activityDate: string;
  message: string;
  item: ActivityEnhancedWithLikes;
}

export function TeamFeedCard(props: CardProps) {
  const refetchAllActivities = useRefetch({ isAllActivities: true });

  const { mutate: likeActivity } = useMutation(addLike, {
    onSettled: () => void refetchAllActivities()
  });

  const { mutate: unlikeActivity } = useMutation(removeLike, {
    onSettled: () => void refetchAllActivities()
  });

  const { creationDate, activityDate, message, item } = props;
  let activityType = getActivityType(item.type, item.category);

  function handleLike() {
    if (item.isLikedByUser) {
      return unlikeActivity(item.activityId);
    }

    return likeActivity(item.activityId);
  }

  function renderLikeButton() {
    return item.isLikedByUser ? <RedLikeIcon /> : <UnlikedIcon />;
  }

  function renderAmountOfLikes(amount: number) {
    return amount > 0 ? amount : ' ';
  }

  function renderGroupIcon() {
    return item.teamActivity ? (
      <GroupIcon color={activityType.color}>
        <SvgIcon component={GroupIconSvg} />
      </GroupIcon>
    ) : (
      <div />
    );
  }


  return (
    <FeedCard>
      <Avatar style={{ backgroundColor: activityType.color, marginRight: 16, alignSelf: 'center' }}>
        {activityType.icon === undefined ? (
          <Typography variant="body2">N/A</Typography>
        ) : (
          <SvgIcon component={activityType.icon} />
        )}
      </Avatar>
      <TeamFeedName>{item.userName}</TeamFeedName>
      {renderGroupIcon()}
      <div />
      <Message>{message}</Message>
      <div />
      <div />
        <TimeStamp>{activityDate}</TimeStamp>
        <div />
        <div />
        <ActivityDate>{creationDate}</ActivityDate>
      <LikeButtonContainer>
        <LikeCountText>{renderAmountOfLikes(item.amountOfLikes)}</LikeCountText>
        <LikeButton onClick={handleLike}>{renderLikeButton()}</LikeButton>
      </LikeButtonContainer>
    </FeedCard>
  );
}
