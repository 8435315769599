import React from 'react';

import { useRanking } from '../UserProvider/UserProvider';
import { RankingGrid } from './RankingGrid';
import { IconButton } from '@material-ui/core';
import { ReactComponent as TrophyIcon } from '../../assets/icons/emoji_events_black_24dp.svg';
import { PageTitle } from '../../globalStyle/FontStyles';
import { TopThreeRankingContainer, RankingTitleContainer } from './TopThreeRanking.style';

export const TopThreeRanking: React.FC = () => {
  const rankingQuery = useRanking();

  if (!rankingQuery || !rankingQuery.data) {
    return null;
  }

  const rankingList = rankingQuery.data;

  return (
    <TopThreeRankingContainer>
      <RankingTitleContainer>
        <IconButton edge="start" color="inherit" aria-label="menu" style={{ padding: 0, margin: 0 }}>
          <TrophyIcon />
        </IconButton>
        <PageTitle>UXMA fitness champions</PageTitle>
      </RankingTitleContainer>
      <RankingGrid ranking={rankingList} />
    </TopThreeRankingContainer>
  );
};
