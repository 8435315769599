import React from 'react';

import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { ButtonProgress, ComponentWrapper, DividerStyled } from './NewActivityPage.style';
import { DropdownMenu } from '../../components/DropdownMenu/DropdownMenu';
import ActionButton from '../../components/Button/ActionButton';
import { Copy } from '../../globalStyle/FontStyles';
import { allActivityTypes } from '../../models/ActivityType';

import { ReactComponent as AddIcon } from '../../assets/icons/add_black_24dp.svg';
import { Page } from '../../models/Page';
import { DurationSlider } from '../../components/DurationSlider/DurationSlider';
import { Switch } from '../../components/Switch/Switch';
import { useActivityForm } from '../../hooks/useActivityForm';
import { useCreateActivity } from '../../hooks/useCreateActivity';
import { DateTimePicker } from '../../components/DateTimePicker/DateTimePicker';

export const NewActivityPage: React.FC = () => {
  const { activityForm, setDuration, setType, setDate, setIsTeamActivity, setIsPublicActivity } = useActivityForm();
  const { postActivity, isLoading } = useCreateActivity();

  const handleDurationChange = (duration: any) => {
    if (duration > 480) {
      setDuration(480);
    } else {
      setDuration(duration);
    }
  };
  const handleTeamActivityChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setIsTeamActivity(event.target.checked);
  const handlePublicActivityChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setIsPublicActivity(event.target.checked);
  const handleChangeOfDate = (date: any) => {
    if (date) {
      setDate(date['_d']);
    }
  };
  const handleActivityChange = (activity: string) => setType(activity);
  const handleSaveButtonClick = () => postActivity(activityForm);

  const PageDescription = (
    <ComponentWrapper>
      <Copy>
        You just had another successful workout? Tell your colleagues all about it! All you have to do is give a few
        details about your workout, set whether you succeeded as a team <i>(go team)</i> and whether you want to post it
        for everyone to see <i>(of course)</i>. We're all excited to see what you achieved &#129351;
      </Copy>
    </ComponentWrapper>
  );

  const Slider = (
    <div style={{ justifySelf: 'center' }}>
      <ComponentWrapper hasMarginBottom>
        <DurationSlider duration={activityForm.duration} onChange={handleDurationChange} />
      </ComponentWrapper>
    </div>
  );

  const ActivityDropdown = (
    <ComponentWrapper hasMarginBottom>
      <DropdownMenu
        placeholder={'Type of activity'}
        items={allActivityTypes.sort()}
        onChangeValue={handleActivityChange}
      />
    </ComponentWrapper>
  );

  const TeamActivitySwitch = (
    <ComponentWrapper hasMarginBottom>
      <Switch checked={activityForm.isTeamActivity} name="isTeamActivity" onChange={handleTeamActivityChange}>
        We did it as a team
      </Switch>
    </ComponentWrapper>
  );

  const PublicActivitySwitch = (
    <ComponentWrapper hasMarginBottom>
      <Switch checked={activityForm.isPublicActivity} name="isPublicActivity" onChange={handlePublicActivityChange}>
        This activity should be published
      </Switch>
    </ComponentWrapper>
  );

  const DatePicker = (
    <ComponentWrapper>
      <DateTimePicker onChange={handleChangeOfDate} value={activityForm.date} />
    </ComponentWrapper>
  );

  const SaveButton = (
    <ComponentWrapper>
      <ActionButton
        icon={<AddIcon />}
        hasMargin
        disabled={activityForm.type.length === 0 || isLoading || activityForm.duration < 1}
        onClick={handleSaveButtonClick}
      >
        Save new activity
        {isLoading && <ButtonProgress size={24} />}
      </ActionButton>
    </ComponentWrapper>
  );

  return (
    <PageTemplate disableRefresh={true} title="New Activity" currentPage={Page.NEW_ACTIVITY} isPaddingTop={true}>
      {PageDescription}
      <DividerStyled />

      {ActivityDropdown}
      {DatePicker}
      <DividerStyled />
      {Slider}
      <DividerStyled />
      {TeamActivitySwitch}
      {PublicActivitySwitch}

      <DividerStyled hasMarginTop={true} />
      {SaveButton}
    </PageTemplate>
  );
};
