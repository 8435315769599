import { Configuration, RedirectRequest } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export function getMsalConfig(): Configuration {
  console.log(buildRedirectUri());

  return {
    auth: {
      clientId: process.env.REACT_APP_MICROSOFT_AL_CLIENT_ID ?? '7c64796a-8e1e-45c6-b2ed-7e7a434c4ec2',
      authority: process.env.REACT_APP_MICROSOFT_AL_AUTHORITY ?? 'https://login.microsoftonline.com/a9d4e36e-c4df-405f-8e71-afd094c37130',
      redirectUri: buildRedirectUri()
    }
  };
}

function buildRedirectUri() {
  if (window.location.host.startsWith('localhost')) {
    return `${window.location.protocol}//${window.location.host}/`;
  }

  return process.env.REACT_APP_URL ?? 'https://fitness.uxma.io/';
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: ['email', 'openid', 'profile', 'User.Read'],
  redirectStartPage: '/'
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
};
