import React, { useEffect, useMemo } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { ButtonProgress, ComponentWrapper, DividerStyled } from '../NewActivityPage/NewActivityPage.style';
import ActionButton from '../../components/Button/ActionButton';
import { DurationSlider } from '../../components/DurationSlider/DurationSlider';
import { Switch } from '../../components/Switch/Switch';
import { useActivityForm } from '../../hooks/useActivityForm';
import { DateTimePicker } from '../../components/DateTimePicker/DateTimePicker';
import { useActivity, useRefetch } from '../../components/UserProvider/UserProvider';
import { CenteredProgress } from '../../components/CenteredProgress/CenteredProgress';
import { deleteActivity } from '../../facades/BackendFacade';
import { useUpdateActivity } from '../../hooks/useUpdateActivity';
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage';

export const ActivityDetailPage: React.FC = () => {
  const history = useHistory();
  const refetch = useRefetch();
  const { activityId } = useParams<{ activityId?: string }>();
  const { activity, errorLoadingActivity, isLoadingActivity } = useActivity(activityId);
  const { activityForm, setDuration, setDate, setIsTeamActivity, update } = useActivityForm(activity);

  const { patchActivity, isLoading: updateIsLoading } = useUpdateActivity(activityId);

  const { mutate: mutateDeleteActivity, isLoading: deleteIsLoading } = useMutation(deleteActivity, {
    onSuccess: () => {
      history.goBack();
      void refetch();
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateForm = useMemo(() => update, []);

  useEffect(() => {
    updateForm(activity);
  }, [activity, updateForm]);

  const handleDurationChange = (duration: any) => {
    if(duration > 480) {
      setDuration(480);
    } else {
      setDuration(duration);
    }
  };
  const handleTeamActivityChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setIsTeamActivity(event.target.checked);
  const handleChangeOfDate = (date: any) => {
    if (date) {
      setDate(date['_d']);
    }
  };
  const handleDeleteClick = () => {
    if (activity) {
      mutateDeleteActivity(activity.activityId);
    }
  };
  const handleUpdateClick = () => {
    if (activity) {
      patchActivity(activityForm);
    }
  };

  if (isLoadingActivity) {
    return <CenteredProgress />;
  }

  if (errorLoadingActivity || !activity) {
    return (
      <PageTemplate title="Error">
        <ErrorMessage>{errorLoadingActivity ?? 'Missing Activity.'}</ErrorMessage>
      </PageTemplate>
    );
  }

  const Slider = (
    <ComponentWrapper hasMarginBottom>
      <DurationSlider duration={activityForm.duration} onChange={handleDurationChange} />
    </ComponentWrapper>
  );

  const TeamActivitySwitch = (
    <ComponentWrapper hasMarginBottom>
      <Switch checked={activityForm.isTeamActivity} name="isTeamActivity" onChange={handleTeamActivityChange}>
        We did it as a team
      </Switch>
    </ComponentWrapper>
  );

  const DatePicker = (
    <ComponentWrapper>
      <DateTimePicker onChange={handleChangeOfDate} value={activityForm.date} />
    </ComponentWrapper>
  );

  const UpdateButton = (
    <ComponentWrapper>
      <ActionButton onClick={handleUpdateClick} hasMargin disabled={!activity}>
        Update activity
        {updateIsLoading && <ButtonProgress size={24} />}
      </ActionButton>
    </ComponentWrapper>
  );

  const DeleteButton = (
    <ComponentWrapper>
      <ActionButton white={true} onClick={handleDeleteClick} hasMargin disabled={!activity}>
        Delete activity
        {deleteIsLoading && <ButtonProgress size={24} />}
      </ActionButton>
    </ComponentWrapper>
  );

  return (
    <PageTemplate showBackButton disableRefresh={true} title={activityForm.type}>
      {Slider}
      {TeamActivitySwitch}
      {DatePicker}
      <DividerStyled hasMarginTop={true} />
      {UpdateButton}
      {DeleteButton}
    </PageTemplate>
  );
};
